<template>
  <div class="manage-calculate" :class="{skeleton: !state.loaded}">
    <div class="row">
      <div class="col" v-for="(f, idx) in state.files" :key="idx">
        <a class="card" :href="f.href" :target="f.href ? '_blank' : undefined" :rel="f.href ? 'noopener' : undefined">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <div class="title font-sm card-title text-uppercase text-muted mb-0">
                  <span class="font-xs">{{ f.desc }}</span>
                </div>
                <span class="value font-weight-bold mb-0">{{ f.title }}</span>
                <div class="font-xs text-muted mt-1">{{ f.subtitle || "&nbsp;" }}</div>
              </div>
              <div class="col-auto">
                <div class="icon icon-shape text-white rounded-circle shadow" :class="f.color">
                  <i class="ni" :class="f.icon"></i>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import router from "@/scripts/router";
import http from "@/scripts/http";
import lib from "@/scripts/lib";

function Component(initialize) {
  this.name = "pageManageCalculateOld";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(async () => {
      await http.post(`/api/maker/project/${projectType}/${projectSeq}/calculate`);

      for (let i = 0; i < 4; i += 1) {
        state.files.push({
          name: "wait",
          desc: "Wait a moment",
          title: "Wait",
        });
      }

      state.loaded = false;
      http.get(`/api/maker/project/${projectType}/${projectSeq}/calculate`).then(({data}) => {
        state.loaded = true;

        state.calculateDetail.calculateAmt = data.body.calculateAmt;
        state.calculateDetail.calculateStatusCode = data.body.calculateStatusCode;

        if (state.calculateDetail.calculateStatusCode) {
          for (let c of data.body.calculateCodeList) {
            if (state.calculateDetail.calculateStatusCode === c.codeId) {
              state.calculateStatus = c.codeIdName;
              break;
            }
          }
        } else {
          state.calculateStatus = "정산예정";
        }

        state.files = [];

        state.files.push({
          name: "bizDocFile",
          title: "사업자등록증",
          subtitle: "또는 주민등록증",
          desc: data.body.bizDocFile?.length ? "클릭하여 확인" : "(없음)",
          color: "bg-gradient-info",
          icon: "ni-cloud-download-95",
          href: data.body.bizDocFile?.length ? data.body.bizDocFile[0].filePath + data.body.bizDocFile[0].fileSaveName : undefined
        });

        state.files.push({
          name: "bankbook",
          title: "통장 사본",
          subtitle: "",
          desc: data.body.bankbookFile?.length ? "클릭하여 확인" : "(없음)",
          color: "bg-gradient-primary",
          icon: "ni-cloud-download-95",
          href: data.body.bankbookFile?.length ? data.body.bankbookFile[0].filePath + data.body.bankbookFile[0].fileSaveName : undefined
        });

        state.files.push({
          name: "settlementFile",
          title: "정산서",
          subtitle: "",
          desc: data.body.settlementFile?.length ? "클릭하여 확인" : "(없음)",
          color: "bg-gradient-green",
          icon: "ni-cloud-download-95",
          href: state.calculateStatus === "정산완료" && data.body.settlementFile?.length ? data.body.settlementFile[0].filePath + data.body.settlementFile[0].fileSaveName : undefined
        });

        state.files.push({
          name: "settlementFile",
          title: state.calculateStatus === "정산완료" ? `${lib.getNumberFormat(state.calculateDetail.calculateAmt)}원` : state.calculateStatus,
          subtitle: "",
          desc: "정산 금액",
          color: "bg-gradient-default",
          icon: "ni-money-coins",
          href: data.body.settlementFile?.length ? data.body.settlementFile[0].filePath + data.body.settlementFile[0].fileSaveName : undefined
        });
      });
    });

    const state = reactive({
      loaded: false,
      calculateStatus: "",
      calculateDetail: {
        calculateAmt: 0,
        calculateStatusCode: "",
      },
      files: []
    });

    const projectType = router.app.$route.params.projectType;
    const projectSeq = router.app.$route.params.projectSeq;

    return {component, state};
  }
});
</script>

<style lang="scss" scoped>
.manage-calculate {
  > .row > div > .card {
    margin-bottom: 0;
    border: $px1 solid #eee;
    box-shadow: none;
    transition: background-color 0.18s;

    &[href]:hover {
      background: $colorBackground;
    }
  }

  &.skeleton {
    > .row > div > .card {

      .title > span, .value, .icon {
        @include skeleton;
      }
    }
  }
}
</style>
